/**
 * Rudimentary, custom, "ago" text formatter. Doesn't handle complex date situations like DST.
 * Used instead of the standard Relative Time plug-in to ensure mobile app and web "ago" formatting
 * are consistent.
 * @see https://github.com/cloudninewx/OpenSnow-Android-Neue/blob/master/utility/src/main/java/com/cloudnineweather/android/utility/extensions/DateExtensions.kt#L28-L110
 * @see https://day.js.org/docs/en/plugin/relative-time
 */
const short = 'short';
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

export const timeAgo = (input, dayjsClass, dayjsFactory, style) => {
  const pluralize = (string, diff) => `${string}${diff === 1 ? '' : 's'}`;
  const now = input.$u ? dayjsFactory.utc() : dayjsFactory();
  const interval = now.diff(input, 's');
  let diff;

  if (interval < MINUTE * 3) {
    return 'Just now';
  }

  if (interval < HOUR) {
    diff = parseInt(interval / 60, 10);

    if (style === short) {
      return `${diff}m`;
    }

    return `${diff} ${pluralize('minute', diff)} ago`;
  }

  if (interval < DAY) {
    diff = parseInt(interval / HOUR, 10);

    if (style === short) {
      return `${diff}h`;
    }

    return `${diff} ${pluralize('hour', diff)} ago`;
  }

  if (interval < MONTH) {
    diff = parseInt(interval / DAY, 10);

    if (style === short) {
      return `${diff}d`;
    }

    return `${diff} ${pluralize('day', diff)} ago`;
  }

  if (interval < YEAR) {
    diff = parseInt(interval / MONTH, 10);

    if (style === short) {
      return `${diff}m`;
    }

    return `${diff} ${pluralize('month', diff)} ago`;
  }

  diff = parseInt(interval / YEAR, 10);

  if (style === short) {
    return `${diff}y`;
  }

  return `${diff} ${pluralize('year', diff)} ago`;
};

export const timeAgoPlugin = (option, dayjsClass, dayjsFactory) => {
  /* eslint no-param-reassign: off */

  // extend dayjs()
  dayjsClass.prototype.timeAgo = function handleTimeAgo(style) {
    return timeAgo(this, dayjsClass, dayjsFactory, style);
  };
};
