<script setup>
// Redirect to last compare page viewed or the summary page if none have been viewed recently.

import { addQueryParamsToPath } from '@@/utils/CommonUtils';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';

definePageMeta({ layout: false });

const { params } = useRoute();
const userFavoritesStore = useUserFavoritesStore();
const activeList = userFavoritesStore.getActiveList;

const { lastComparePageCookie, queryCompareField } = useCompareUtils(activeList);
const comparePage = lastComparePageCookie.value || 'snow-summary';

let url = `/explore/${params.compareType}/${params.compareId}/${comparePage}`;

if (queryCompareField) {
  url = addQueryParamsToPath(url, { compare: queryCompareField });
}

navigateTo(url, { replace: true });
</script>

<template>
  <div />
</template>
