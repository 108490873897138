export default {
  /**
   * Custom Vue Router scroll behavior:
   *
   * - Smooth scroll to top of new page
   * - Instant scroll to saved position of previous page
   * - Smooth scroll to anchor in new page when hash present in URL
   *
   * @see https://nuxt.com/docs/guide/recipes/custom-routing#using-approuteroptions
   * @see https://router.vuejs.org/guide/advanced/scroll-behavior.html
   */
  scrollBehavior(to, from, savedPosition) {
    // console.log(`scrollBehavior(): to.path = ${to.path}, to.hash = ${to.hash}, from.path = ${from.path}, savedPosition = ${JSON.stringify(savedPosition, null, 2)}`);

    let delay = 0;
    let scrollTo = { left: 0, top: 0, behavior: 'smooth' };

    if (savedPosition) {
      // Use de-structuring assignment so the savedPosition isn't accidentally modified!
      scrollTo = { ...savedPosition };

      // A slight delay is used when navigating back/forward, to a previous position, so that the
      // page has enough content to scroll to the requested position.
      delay = 1000;
    }
    else if (to.hash) {
      scrollTo.el = to.hash;
      scrollTo.top = 84; // Allow space for site header and top margin
      delay = 1000;
    }

    return new Promise((resolve) => {
      window.setTimeout(() => {
        // console.log(`scrollBehavior(): scrollTo = ${JSON.stringify(scrollTo)}, delay = ${delay}`);
        resolve(scrollTo);
      }, delay);
    });
  },
};
