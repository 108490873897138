<template>
  <div
    :class="buttonWrapperClass"
  >
    <button
      :class="buttonClass"
      @click="$emit('click')"
    >
      <font-awesome-icon
        class="tw-w-3/5 tw-h-3/5"
        icon="times-circle"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'CardCloseButton',

  props: {
    buttonClass: {
      type: [Array, String],
      default: 'tw-text-gray-400',
    },
    hasBodyPaddingSmall: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  computed: {
    buttonWrapperClass() {
      return [
        'tw-absolute tw-right-0',
        this.hasBodyPaddingSmall ? 'lg:tw-right-1' : 'lg:tw-right-3',
        this.hasBodyPaddingSmall ? 'tw-top-1.5' : 'tw-top-3',
        'tw-flex tw-items-center tw-justify-center',
        'tw-w-8 tw-h-8',
      ];
    },
  },
};
</script>
