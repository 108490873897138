import { defineStore } from 'pinia';

export const useUiStore = defineStore('ui', {
  state: () => ({
    loading: false,
    shouldCollapseNav: false,
    weatherStationSlug: null,
  }),

  actions: {
    setLoading(value) {
      this.loading = value;
    },

    setShouldCollapseNav(value) {
      this.shouldCollapseNav = value;
    },

    setUiProperties(obj = {}) {
      Object.entries(obj).forEach(([key, value]) => {
        this[key] = value;
      });
    },
  },
});
