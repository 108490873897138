export function useCookieWrapper(
  name,
  maxAge = 60 * 24 * 60 * 60, // 60 days
) {
  const config = useRuntimeConfig().public;
  const { siteName = 'OpenSnow' } = config;
  const cookieName = `${siteName}-${name}`.toLowerCase();
  const domain = `${siteName.toLowerCase()}.com`;

  const options = {
    domain: domain,
    maxAge,
    path: '/',
  };

  return useCookie(cookieName, options);
}
