<template>
  <transition
    :enter-active-class="$style.fadeEnterTransition"
    :enter-from-class="$style.fadeOut"
    :leave-active-class="$style.fadeLeaveTransition"
    :leave-to-class="$style.fadeOut"
  >
    <div
      v-if="showLoading"
      :class="[$style.container, 'loading-background-color']"
    >
      <LoadingIndicator />
    </div>
  </transition>
</template>

<script>
import { mapState } from 'pinia';
import { useUiStore } from '@/stores/Ui';

/**
 * Custom Loading Indicator with SVG borrowed from https://codepen.io/supah/pen/BjYLdW
 * @see https://nuxtjs.org/docs/features/loading/#using-a-custom-loading-component
 */
export default {
  name: 'Loading',

  setup() {
    const showLoading = ref(false);
    const startTime = ref(null);
    const timeoutId = ref(null);

    return {
      showLoading,
      startTime,
      timeoutId,
    };
  },

  computed: {
    ...mapState(useUiStore, ['loading']),
  },

  watch: {
    loading(value) {
      if (value) {
        this.start();
      }
      else {
        this.finish();
      }
    },
  },

  methods: {
    /**
     * Show the loading indicator 200ms after the page transition begins. If the fetch request
     * completes within 200ms then the loading indicator won't be shown.
     */
    start() {
      // Loading may be started more than once so clear previous timeout before starting a new one!
      window.clearTimeout(this.timeoutId);

      this.timeoutId = window.setTimeout(() => {
        this.showLoading = true;
        this.startTime = Date.now();
      }, 200);
    },

    /**
     * Ensure that the loading indicator is displayed for a minimum of 1s, including the
     * transition times, to avoid brief flashes of content that could be confusing to the user.
     */
    finish() {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;

      if (!this.startTime) {
        return;
      }

      const minimumDuration = 625;
      const duration = Date.now() - this.startTime;

      if (duration >= minimumDuration) {
        this.showLoading = false;
        this.startTime = null;
      }
      else {
        window.setTimeout(() => this.finish(), minimumDuration - duration);
      }
    },
  },
};
</script>

<style module>
/*
 * Tailwind.css is not used in this component because it is rendered outside the top level #root
 * element which is used to namespace Tailwind selectors.
 */
.fadeEnterTransition {
  transition: opacity 0.125s;
}

.fadeLeaveTransition {
  transition: 0.25s;
}

.fadeOut {
  opacity: 0;
}

.container {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 51;
}
</style>
