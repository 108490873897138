import { useCookieWrapper } from '@@/composables/useCookieWrapper';
import { useUserStore } from '@@/stores/User';

/**
 * When a user accepts or denies tracking and the user logs in then update their privacy settings.
 * If there is an authenticated user and a cookienotice-accepted cookie with a boolean value
 * then update the user's privacy setting to the allow/deny data collection, based on the boolean
 * value of the cookie, and then delete the cookie.
 */
export default defineNuxtRouteMiddleware(async () => {
  const userStore = useUserStore();

  if (userStore.user) {
    const cookieNoticeAcceptedCookie = useCookieWrapper('cookienotice-accepted');
    const dataCollection = cookieNoticeAcceptedCookie.value;

    if (typeof dataCollection === 'boolean') {
      await userStore.updatePrivacy(dataCollection);
      cookieNoticeAcceptedCookie.value = null;
    }
  }
});
