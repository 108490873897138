import * as Sentry from '@sentry/vue';
import { useRuntimeConfig } from 'nuxt/app';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const { public: { isVitest, sentry } } = useRuntimeConfig();

  if (isVitest) {
    console.log('Sentry [CSR]: Not loaded, in Vitest!');
    return;
  }

  if (!sentry?.dsn) {
    console.log('Sentry [CSR]: Not loaded, DSN not set');
    return;
  }

  const {
    allowUrls,
    dsn,
    environment,
    ignoreErrors,
    profilesSampleRate,
    tracesSampleRate,
  } = sentry;

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn,
    environment,
    ignoreErrors,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    profilesSampleRate,
    tracePropagationTargets: allowUrls,
    tracesSampleRate,
  });

  console.log('Sentry [CSR]: Loaded successfully');
});
