import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faBellOn,
  faCameraMovie,
  faEarthAmericas,
  faIdBadge,
  faMapLocationDot,
  faMountain as fadMountain,
  faPaperPlane,
  faTowerCell,
  faUsers as fadUsers,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBars,
  faBell,
  faChevronCircleDown,
  faClock,
  faEdit,
  faInfoCircle,
  faLightbulbOn,
  faLink,
  faLocationDot,
  faMap,
  faMountain,
  faStar as farStar,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowRightFromBracket,
  faBell as fasBell,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCog,
  faCompass,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandArrows,
  faEye,
  faEyeSlash,
  faGlobeAmericas,
  faList,
  faLock,
  faMinusCircle,
  faMobileAlt,
  faNewspaper,
  faPause,
  faPencilAlt,
  faPlay,
  faPlus,
  faQuestion,
  faSearch,
  faSearchPlus,
  faSnowflake,
  faStar,
  faTimesCircle,
  faUser,
  faVideo as fasVideo,
} from '@fortawesome/pro-solid-svg-icons';

const setupLibrary = () => {
  // This is important, we are going to let Nuxt worry about the CSS
  config.autoAddCss = false;

  // You can add your icons directly in this plugin. See other examples for how you
  // can add other styles or just individual icons.
  library.add(
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowRightFromBracket,
    faBars,
    faBell,
    faBellOn,
    faCameraMovie,
    faCheckCircle,
    faChevronCircleDown,
    faChevronLeft,
    faChevronRight,
    faClock,
    faCog,
    faCompass,
    faEarthAmericas,
    faEdit,
    faExclamationCircle,
    faExclamationTriangle,
    faExpandArrows,
    faEye,
    faEyeSlash,
    faGlobeAmericas,
    faIdBadge,
    faInfoCircle,
    faLightbulbOn,
    faLink,
    faList,
    faLocationDot,
    faLock,
    faMap,
    faMapLocationDot,
    faMinusCircle,
    faMobileAlt,
    faMountain,
    faNewspaper,
    faPaperPlane,
    faPause,
    faPencilAlt,
    faPlay,
    faPlus,
    faQuestion,
    faSearch,
    faSearchPlus,
    faSnowflake,
    faStar,
    faTimesCircle,
    faTowerCell,
    faUser,
    faUsers,
    faVideo,
    fadMountain,
    fadUsers,
    farStar,
    fasBell,
    fasVideo,
  );
};

export default defineNuxtPlugin((nuxtApp) => {
  setupLibrary();
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});

export function addFontAwesomeIcon(app) {
  setupLibrary();
  app.component('font-awesome-icon', FontAwesomeIcon, {});
}
