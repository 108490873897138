import { useUiStore } from '@/stores/Ui';

export default defineNuxtPlugin((nuxtApp) => {
  const uiStore = useUiStore();

  const loading = {
    start() {
      uiStore.setLoading(true);
    },

    finish() {
      uiStore.setLoading(false);
    },
  };

  nuxtApp.hook('page:start', () => loading.start());
  nuxtApp.hook('page:finish', () => loading.finish());

  return {
    provide: {
      loading,
    },
  };
});
