import { configure, defineRule } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import { required, email, min } from '@vee-validate/rules';
import en from '@vee-validate/i18n/dist/locale/en.json';

/**
 * @todo Add more vee-validate rules as necessary!
 */
export default defineNuxtPlugin(() => {
  defineRule('required', required);
  defineRule('email', (value) => {
    const valid = email(value);

    if (!valid) {
      return 'That doesn\'t look like a valid email address.';
    }

    return true;
  });
  defineRule('min', min);

  configure({
    generateMessage: localize({ en }),
  });
});
