/* eslint camelcase: 0 */
import { useNuxtApp } from 'nuxt/app';
import { defineStore } from 'pinia';
import { sortDailyReads } from '~/components/DailySnows/SortDailySnowUtils';
import { useUserStore } from '~/stores/User';

/**
 * @todo This helper method is the same as that used in /store/Point.js. This common code should
 * be moved to a common location for easier reuse. Perhaps in a new /utils/StoreUtils.js module?
 */
const fetchForecast = (params = {}) => {
  const {
    is_custom,
    path,
    payload = {},
  } = params;

  const { $api } = useNuxtApp();
  const userStore = useUserStore();

  const cache_key = is_custom ? userStore?.user?.cache_key : null;
  return $api.get(path, { ...payload, cache_key });
};

const getPath = (path, params = {}) => {
  const { coordinates, slug } = params;

  const locationLike = coordinates
    ? coordinates.map((c) => c.toFixed(4)).join(',')
    : slug;

  return `/location/${locationLike}${path}`;
};

export const useLocationStore = defineStore('location', {
  state: () => ({}),

  actions: {
    async fetchAvalanche(params) {
      const { is_custom } = params;

      const { location } = await fetchForecast({
        is_custom,
        path: getPath('/avalanche', params),
      });

      return location;
    },

    async fetchCams(params = {}) {
      const { is_custom, units = 'imperial' } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units },
        path: getPath('/cams', params),
      });

      return location;
    },

    async fetchDailyReads(params = {}) {
      const { is_custom, sort, units = 'imperial' } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units },
        path: getPath('/daily-reads', params),
      });

      sortDailyReads(location.daily_reads, sort);

      return location;
    },

    async fetchForecastCurrent(params = {}) {
      const { is_custom, units = 'imperial', with_geocode = false } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units, with_geocode },
        path: getPath('/forecast/current', params),
      });

      return location;
    },

    async fetchForecastDetail(params = {}) {
      const { is_custom, units = 'imperial' } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units },
        path: getPath('/forecast/detail', params),
      });

      return location;
    },

    async fetchForecastDetailMyLocation(params = {}) {
      const { is_custom, units = 'imperial' } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units },
        path: getPath('/forecast/detail/my-location', params),
      });

      return location;
    },

    async fetchForecastSnowDetail(params = {}) {
      const { is_custom, units = 'imperial', with_weather_stations = true } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units, with_weather_stations },
        path: getPath('/forecast/snow-detail', params),
      });

      return location;
    },

    async fetchForecastSnowSummary(params) {
      const { is_custom, units = 'imperial' } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units },
        path: getPath('/forecast/snow-summary', params),
      });

      return location;
    },

    async fetchForecastSummary(params) {
      const { is_custom, units = 'imperial' } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units },
        path: getPath('/forecast/summary', params),
      });

      return location;
    },

    async fetchHistorySnowDaily(params = {}) {
      const {
        month,
        units = 'imperial',
        year,
      } = params;

      const { location } = await fetchForecast({
        payload: { month, units, year },
        path: getPath('/history/snow-daily', params),
      });

      return location;
    },

    async fetchHistorySnowDetail(params = {}) {
      const { is_custom, units = 'imperial' } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units },
        path: getPath('/history/snow-detail', params),
      });

      return location;
    },

    async fetchInfo(params = {}) {
      const { is_custom } = params;

      const { location } = await fetchForecast({
        is_custom,
        path: getPath('/info', params),
      });

      return location;
    },

    async fetchLocation(params = {}) {
      const { is_custom } = params;

      const { location } = await fetchForecast({
        is_custom,
        path: getPath('', params),
      });

      return location;
    },

    async fetchSnowReport(params = {}) {
      const { is_custom, units = 'imperial', with_weather_stations = true } = params;

      const { location } = await fetchForecast({
        is_custom,
        payload: { units, with_weather_stations },
        path: getPath('/snow-report', params),
      });

      return location;
    },

    async fetchTrailMaps(params = {}) {
      const { is_custom } = params;

      const { location } = await fetchForecast({
        is_custom,
        path: getPath('/trail-maps', params),
      });

      return location;
    },

    async fetchWeatherStations(params = {}) {
      const { is_custom, units = 'imperial' } = params;

      const { location } = await fetchForecast({
        is_custom,
        path: getPath('/weather-stations', params),
        payload: { units },
      });

      return location;
    },
  },
});
