import validate from "/buddy/pipeline-fs/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45redirect_45compare_45pages_45global from "/buddy/pipeline-fs/middleware/00.redirect-compare-pages.global.js";
import _01_45check_45user_45global from "/buddy/pipeline-fs/middleware/01.check-user.global.js";
import _02_45update_45user_45privacy_45global from "/buddy/pipeline-fs/middleware/02.update-user-privacy.global.js";
import manifest_45route_45rule from "/buddy/pipeline-fs/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45redirect_45compare_45pages_45global,
  _01_45check_45user_45global,
  _02_45update_45user_45privacy_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}