<script setup>
import { replaceHistoryState } from '@@/utils/CommonUtils';

definePageMeta({ layout: 'authorized' });

const { query } = useRoute();
const view = ref(query.view || 'locations');

const handleChangeView = (newView) => {
  const { path } = useRoute();
  view.value = newView;
  replaceHistoryState(`${path}?view=${view.value}`);
};
</script>

<template>
  <EditFavoritesPage
    :view="view"
    @change-view="handleChangeView"
  />
</template>
