import { addQueryParamsToPath } from '@@/utils/CommonUtils';

const isWeather = (currentPath) => currentPath.match(/.*\/weather$/);
const isWeatherStations = (currentPath) => currentPath.match(/.*\/weather-stations$/);
const isSnowReport = (currentPath) => currentPath.match(/.*\/snow-report$/);
const isExplore = (currentPath) => currentPath.startsWith('/explore')
  || currentPath.startsWith('/user/favorites/locations');

const isCompareFieldForecast = (compareField) => compareField?.group === 'forecast';
const isCompareFieldHistory = (compareField) => compareField?.group === 'history';
const isCompareFieldSnowReport = (compareField) => compareField?.group === 'snow-report';

const isExploreWeatherPage = (currentPath) => isExplore(currentPath)
  && isWeather(currentPath);
const isExploreSnowSummaryPage = (currentPath) => isExplore(currentPath)
  && currentPath.match(/.*\/snow-summary$/);
const isExploreSnowForecastPage = (currentPath) => isExplore(currentPath)
  && currentPath.match(/.*\/snow-forecast$/);
const isExploreSnowHistoryPage = (currentPath) => isExplore(currentPath)
  && currentPath.match(/.*\/snow-history$/);
const isExploreAllCamsPage = (currentPath) => isExplore(currentPath)
  && currentPath.match(/.*\/all-cams$/);
const isExploreSnowReportPage = (currentPath) => isExplore(currentPath)
  && isSnowReport(currentPath);
const isExploreTemperaturePage = (currentPath) => isExplore(currentPath)
  && currentPath.match(/.*\/temperature$/);

const isLocation = (currentPath) => currentPath.startsWith('/location');
const isLocationWeatherPage = (currentPath) => isLocation(currentPath)
  && isWeather(currentPath);
const isLocationWeatherStationsPage = (currentPath) => isLocation(currentPath)
  && isWeatherStations(currentPath);
const isLocationSnowSummaryPage = (currentPath) => isLocation(currentPath)
  && currentPath.match(/.*\/snow-summary$/);
const isLocationSnowReportPage = (currentPath) => isLocation(currentPath)
  && isSnowReport(currentPath);
const isLocationCamsPage = (currentPath) => isLocation(currentPath)
  && currentPath.match(/.*\/cams$/);

export const addCompareStateToQuery = (url, params = {}) => {
  const { compareField, filter } = params;
  const query = {};

  if (compareField) {
    query.compare = compareField.field;
  }

  if (filter?.countries?.length) {
    query.countries = filter.countries.map((country) => country.code);
  }

  if (filter?.locationTypes?.length) {
    query.locations = filter.locationTypes.map((locationType) => locationType.id);
  }

  return addQueryParamsToPath(url, query);
};

export const addViewToComparePath = (path, params = {}) => {
  const { currentPath = '', compareField } = params;
  let view;

  if (isExploreWeatherPage(currentPath)
    || isLocationWeatherPage(currentPath)) {
    view = 'weather';
  }

  if (isCompareFieldForecast(compareField)
    || isExploreSnowSummaryPage(currentPath)
    || isLocationSnowSummaryPage(currentPath)) {
    view = 'snow-summary';
  }

  if (isExploreSnowForecastPage(currentPath)) {
    view = 'snow-forecast';
  }

  if (isCompareFieldHistory(compareField)
    || isExploreSnowHistoryPage(currentPath)) {
    view = 'snow-history';
  }

  if (isExploreAllCamsPage(currentPath)
    || isLocationCamsPage(currentPath)) {
    view = 'all-cams';
  }

  if (isCompareFieldSnowReport(compareField)
    || isExploreSnowReportPage(currentPath)
    || isLocationSnowReportPage(currentPath)) {
    view = 'snow-report';
  }

  if (isExploreTemperaturePage(currentPath)) {
    view = 'temperature';
  }

  return view ? `${path}/${view}` : path;
};

export const addViewToLocationPath = (path, params = {}) => {
  const { currentPath = '', compareField } = params;
  let view;

  if (isExploreWeatherPage(currentPath)
    || isExploreTemperaturePage(currentPath)
    || isLocationWeatherPage(currentPath)) {
    view = 'weather';
  }

  if (isLocationWeatherStationsPage(currentPath)) {
    view = 'weather-stations';
  }

  if (isCompareFieldForecast(compareField)
    || isExploreSnowSummaryPage(currentPath)
    || isExploreSnowForecastPage(currentPath)
    || isLocationSnowSummaryPage(currentPath)) {
    view = 'snow-summary';
  }

  if (isCompareFieldHistory(compareField)
    || isCompareFieldSnowReport(compareField)
    || isExploreSnowHistoryPage(currentPath)
    || isExploreSnowReportPage(currentPath)
    || isLocationSnowReportPage(currentPath)) {
    view = 'snow-report';
  }

  if (isExploreAllCamsPage(currentPath)
    || isLocationCamsPage(currentPath)) {
    view = 'cams';
  }

  return view ? `${path}/${view}` : path;
};

export const checkCompareField = (field, compareFields, isAllAccess) => {
  const compareField = compareFields.find((cf) => cf.field === field);

  if (compareField && (!compareField.isAllAccess || isAllAccess)) {
    return compareField;
  }

  return null;
};
