<script setup>
import { nextTick } from 'vue';
import { emptyIconViews } from '@@/components/Common/EmptyIcon.vue';
import { parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useLocationStore } from '@@/stores/Location';
import { useUserLocation } from '@@/composables/useUserLocation';
import { useUserStore } from '@@/stores/User';

definePageMeta({ layout: 'authorized' });

const { $toast } = useNuxtApp();
const { getErrorMessage, getUserLocation } = useUserLocation();
const $style = useCssModule();
const locationStore = useLocationStore();
const userStore = useUserStore();

const forecastDay = ref(0);
const location = ref(null);
const showLoading = ref(true);
const userLocation = ref(null);

const locationForecastUnavailable = ref(false);
const errorMessage = ref(null);
const permissionDenied = ref(false);

const units = computed(() => userStore.preferences.units);

const infoSectionClass = 'tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-80';

const learnMoreLinks = [
  { href: 'https://support.google.com/chrome/answer/142065', text: 'Chrome' },
  { href: 'https://support.microsoft.com/en-us/microsoft-edge/location-and-privacy-in-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04', text: 'Edge' },
  { href: 'https://support.mozilla.org/en-US/kb/site-permissions-panel', text: 'Firefox' },
  { href: 'https://support.apple.com/guide/safari/websites-ibrwe2159f50/18.0/mac/15.0', text: 'Safari (MacOS)' },
  { href: 'https://support.apple.com/en-us/102647#:~:text=Go%20to%20Settings%20%3E%20Privacy%20&%20Security,app%20is%20in%20the%20background.', text: 'Safari (iOS)' },
];

const setLocationForecastUnavailable = (e) => {
  const refreshMesg = 'Refresh this page to try again.';

  if (e?.code === 1) {
    errorMessage.value = getErrorMessage(e);
    permissionDenied.value = true;
  }
  else if (e?.code) {
    errorMessage.value = `${getErrorMessage(e)} ${refreshMesg}`;
    permissionDenied.value = false;
  }
  else {
    errorMessage.value = `Unable to get the forecast for your current location. ${refreshMesg}`;
    permissionDenied.value = false;
  }

  locationForecastUnavailable.value = true;
};

const fetchLocation = async () => {
  const slug = userLocation.value.join(',');
  const payload = { slug, units: units.value };
  return locationStore.fetchForecastDetailMyLocation(payload);
};

const getUserLocationAndFetchForecast = async () => {
  try {
    userLocation.value = await getUserLocation({ maximumAge: 5 * 60 * 1000 }); // Cache location for 5 minutes
  }
  catch (e) {
    setLocationForecastUnavailable(e);
    return;
  }

  if (!userLocation.value) {
    setLocationForecastUnavailable();
    return;
  }

  try {
    location.value = await fetchLocation();
  }
  catch (e) {
    // If there is an error fetching the location forecast then inform the user and reset the view
    // to the prompt for geolocation permission state.

    const { message } = parseOpenMountainApiError(e);

    $toast.open({
      message: `Unable to fetch forecast for your location: ${message}`,
      type: 'error',
    });

    setLocationForecastUnavailable();
    location.value = null;
  }
};

const handleClickForecastDay = (newForecastDay) => {
  forecastDay.value = newForecastDay;
};

watch(units, async () => {
  location.value = await fetchLocation();
});

onMounted(async () => {
  showLoading.value = true;
  await nextTick();
  await getUserLocationAndFetchForecast();
  showLoading.value = false;
});
</script>

<template>
  <PageContent class="tw-pb-10">
    <LocationLoading
      v-if="showLoading"
    />
    <div v-else-if="location">
      <div class="tw-my-3 lg:tw-mt-6 lg:tw-mb-7">
        <SnowLocationHeader
          :is-my-location="true"
          :is-page-header="true"
          :location="location"
        />
      </div>
      <WeatherPageContent
        class="tw--mt-4"
        :forecast-day="forecastDay"
        :is-my-location="true"
        :location="location"
        @click-forecast-day="handleClickForecastDay"
      />
    </div>
    <div
      v-else-if="locationForecastUnavailable"
      :class="infoSectionClass"
    >
      <h2 class="section-title error-message-color tw-mb-4 lg:tw-mb-8 tw-text-center">
        Forecast Unavailable for Your Location
      </h2>
      <p class="tw-mb-4 lg:tw-mb-8">
        <EmptyIcon
          :view="emptyIconViews.myLocation"
        />

        <span>
          {{ errorMessage }}
        </span>
        <span v-if="permissionDenied">
          Please enable location access for OpenSnow to view forecast information for your current
          location.
        </span>
        <span v-if="permissionDenied">
          Learn more at the following links for your browser:
          <NuxtLink
            v-for="({ href, text }) in learnMoreLinks"
            :key="text"
            :class="[
              'tw-underline link-color-brand',
              'tw-mr-1 last:tw-mr-0',
              $style.learnMoreLink,
            ]"
            :external="true"
            :href="href"
          >
            {{ text }}
          </NuxtLink>.
        </span>
      </p>
    </div>
  </PageContent>
</template>

<style module>
.learnMoreLink::after {
  content: ',';
}

.learnMoreLink:last-child::after {
  content: '';
}
</style>
