import { point } from '@turf/helpers';
import distance from '@turf/distance';

export const canUseSortCookie = (cookie, filters, canSortByClosest) => {
  if (!filters.includes(cookie)) {
    return false;
  }

  if (cookie === 'Closest') {
    return !!canSortByClosest;
  }

  return true;
};

export const sortDailyReads = (dailyReads, sort, location) => {
  if (Array.isArray(dailyReads) === false) {
    return;
  }

  if (sort === 'Last Updated') {
    dailyReads.sort((a, b) => {
      const aPostedAt = new Date(a.post.posted_at).getTime();
      const bPostedAt = new Date(b.post.posted_at).getTime();
      return bPostedAt - aPostedAt;
    });
  }
  else if (sort === 'A-Z') {
    dailyReads.sort((a, b) => a.name.localeCompare(b.name));
  }
  else if (sort === 'Forecaster') {
    dailyReads.sort((a, b) => a.post.author.name.localeCompare(b.post.author.name));
  }
  else if (sort === 'Closest') {
    const distanceMap = dailyReads.reduce((acc, dailyRead) => {
      const { name } = dailyRead;
      const from = point(location);
      const to = point(dailyRead.coordinates.point);
      acc[name] = distance(from, to);
      return acc;
    }, {});

    dailyReads.sort((a, b) => distanceMap[a.name] - distanceMap[b.name]);
  }
};

export const getSortOptions = (params = {}) => {
  const { showRecommended = false, showClosest = false } = params;

  return [
    showRecommended ? 'Recommended' : null,
    'Last Updated',
    'A-Z',
    showClosest ? 'Closest' : null,
    'Forecaster',
  ].filter((option) => option !== null);
};
