<template>
  <footer class="brand-color tw-py-8 tw-px-3.5">
    <div
      :class="[
        $style.centeredRow,
        'tw-text-xs tw-flex tw-items-center tw-mb-2.5',
      ]"
    >
      <span>
        Light Mode
      </span>
      <ToggleSwitch
        v-model="isDarkMode"
        class="tw-mx-2"
      />
      <span>
        Dark Mode
      </span>
    </div>
    <div
      :class="[
        $style.centeredRow,
        'tw-text-xs tw-mb-5 tw-mt-2.5 tw-text-center',
      ]"
    >
      <span>Units:</span>
      <a
        v-for="{ name, unit } in units"
        :key="unit"
        :class="getUnitClassName(unit)"
        href="javascript:void(0);"
        rel="nofollow"
        @click="() => { selectedUnits = unit; }"
      >
        {{ name }}
      </a>
    </div>
    <div class="tw-text-center">
      <img
        :alt="config.siteName"
        :class="[$style.siteLogoImage, 'tw-inline-block']"
        :src="siteLogoImageUrl"
      >
    </div>
    <div
      v-for="(footerLinks, index) in listOfFooterLinks"
      :key="index"
      :class="[$style.footerLinksRow, 'tw-mx-auto', 'tw-my-4']"
    >
      <ul class="tw-flex tw-flex-nowrap tw-justify-center">
        <li
          v-for="footerLink in footerLinks"
          :key="footerLink.text"
          :class="[
            $style.footerLinkItem,
            'tw-inline-block lg:tw-mx-3',
          ]"
        >
          <NuxtLink
            v-if="footerLink.to"
            :to="footerLink.to"
          >
            {{ footerLink.text }}
          </NuxtLink>
          <a
            v-else
            :href="footerLink.href"
            :target="footerLink.target"
            v-on="footerLink.click ? { click: footerLink.click } : {}"
          >
            {{ footerLink.text }}
          </a>
        </li>
      </ul>
    </div>
    <DownloadAppButtons />
    <ul class="tw-flex tw-justify-center tw-my-4">
      <li
        v-for="socialMediaLink in socialMediaLinks"
        :key="socialMediaLink.url"
        class="tw-mx-4"
      >
        <a
          :href="socialMediaLink.url"
          target="_blank"
        >
          <span
            class="tw-inline-block tw-w-8 tw-h-8 tw-align-middle"
            :style="socialMediaLink.style"
          />
        </a>
      </li>
    </ul>
    <p class="tw-text-xs tw-p-6 tw-text-center">
      &copy;&nbsp;{{ copyrightMessage }}
    </p>
  </footer>
</template>

<script>
import { filename } from 'pathe/utils';
import { mapActions, mapState } from 'pinia';
import { getMaskImageStyle } from '@@/utils/CommonUtils';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';
import { useRuntimeConfig } from 'nuxt/app';

export default {
  name: 'SiteFooter',

  setup() {
    const config = useRuntimeConfig().public;

    // POC: Work around for dynamic images in Vite which aren't supported as easily as with Webpack
    // SEE: https://www.lichter.io/articles/nuxt3-vue3-dynamic-images/
    const glob = import.meta.glob('@/assets/img/*LogoMarkWhite.png', { eager: true });
    const images = Object.fromEntries(
      Object.entries(glob).map(([key, value]) => [filename(key), value.default]),
    );

    return { config, images };
  },

  data() {
    return {
      units: [
        { name: 'USA', unit: 'imperial' },
        { name: 'Metric', unit: 'metric' },
      ],
    };
  },

  computed: {
    ...mapState(useUserStore, ['isGuest', 'preferences']),

    copyrightMessage() {
      return `${new Date().getFullYear()} ${this.config.siteName}. Cloudnine Weather LLC. All Rights Reserved.`;
    },

    /**
     * Use a computed getter to return true for the isDarkMode property when the theme is "dark"
     * and set the theme preference when the toggle value is changed.
     * @see https://vuejs.org/v2/guide/computed.html#Computed-Setter
     */
    isDarkMode: {
      get() {
        return this.preferences.theme === 'dark';
      },

      set(isDarkMode) {
        this.setPreference({ theme: isDarkMode ? 'dark' : 'light' });
      },
    },

    links() {
      return {
        appLink: {
          text: 'App',
          to: '/app',
        },
        climateLink: {
          text: 'Climate',
          to: '/news/post/opensnow-electricity-usage-100-renewable',
        },
        gettingStartedLink: {
          href: 'https://support.opensnow.com/getting-started',
          target: '_blank',
          text: 'Getting Started',
        },
        privacyPolicyLink: {
          text: 'Privacy Policy',
          to: '/privacy',
        },
        shopLink: {
          href: 'https://shop.opensnow.com',
          target: '_blank',
          text: 'Shop',
        },
        supportLink: {
          href: 'https://support.opensnow.com/',
          target: '_blank',
          text: 'Support',
        },
        teamLink: {
          text: 'Team',
          to: '/team',
        },
        termsOfUseLink: {
          text: 'Terms of Use',
          to: '/terms',
        },
      };
    },

    listOfFooterLinks() {
      const allAccessLink = {
        text: 'All-Access',
        to: '/allaccess',
      };

      const whatsNewLink = {
        text: "What's New",
        to: '/news/company-updates',
      };

      const featureGuidesLink = {
        href: 'https://support.opensnow.com/feature-guides',
        target: '_blank',
        text: 'Feature Guides',
      };

      const flakesPodcastLink = {
        href: 'https://open.spotify.com/show/2hDI6YOg6PLXUKYoOrWV1s?si=dVM_oEi6TfmqlyHmzLEqVw&nd=1',
        target: '_blank',
        text: 'Flakes Podcast',
      };

      const aboutLink = {
        text: 'About',
        to: '/about',
      };

      const careersLink = {
        text: 'Careers',
        to: '/careers',
      };

      const contactLink = {
        text: 'Contact',
        to: '/contact',
      };

      const advertiseLink = {
        href: 'https://support.opensnow.com/contact-us/advertising-on-opensnow',
        target: '_blank',
        text: 'Advertise',
      };

      const partnershipPolicyLink = {
        text: 'Partnership Policy',
        to: '/partnerships',
      };

      // NOTE: An array of arrays is returned so that the OpenSnow footer links can be rendered on
      // multiple lines with precise control over which links appear on which links.
      return [
        [
          allAccessLink,
          this.links.appLink,
          whatsNewLink,
        ],
        [
          this.links.gettingStartedLink,
          featureGuidesLink,
          flakesPodcastLink,
        ],
        [
          aboutLink,
          this.links.teamLink,
          careersLink,
          contactLink,
          this.links.climateLink,
          this.links.shopLink,
          advertiseLink,
        ],
        [
          this.links.supportLink,
          this.links.termsOfUseLink,
          this.links.privacyPolicyLink,
          partnershipPolicyLink,
        ],
      ];
    },

    selectedUnits: {
      get() {
        return this.preferences.units;
      },

      async set(units) {
        await this.fetchSeed({ forceUpdate: true, units });
        await this.setPreference({ units });
      },
    },

    siteLogoImageUrl() {
      return this.images[`${this.config.siteName}LogoMarkWhite`];
    },

    socialMediaLinks() {
      return [
        {
          style: {
            backgroundColor: 'currentColor',
            ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/brands/facebook.svg'),
          },
          url: 'https://www.facebook.com/OpenSnow',
        }, {
          style: {
            backgroundColor: 'currentColor',
            ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/brands/instagram.svg'),
          },
          url: 'https://www.instagram.com/opensnow/',
        }, {
          style: {
            backgroundColor: 'currentColor',
            ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/brands/x-twitter.svg'),
          },
          url: 'https://x.com/OpenSnow',
        },
        {
          style: {
            backgroundColor: 'currentColor',
            ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/brands/youtube.svg'),
          },
          url: 'https://www.youtube.com/@Open-Snow',
        },
      ];
    },
  },

  methods: {
    // ...mapActions(useLoginStore,, ['makeLogoutRequest']),
    ...mapActions(useMetaStore, ['fetchSeed']),
    ...mapActions(useUserStore, ['setPreference']),

    getUnitClassName(unit) {
      return [
        'tw-ml-1.5',
        this.selectedUnits === unit ? 'tw-font-bold' : undefined,
      ];
    },

    handleLogoutLinkClick(e) {
      e.preventDefault();
      e.stopImmediatePropagation();

      this.makeLogoutRequest().then(() => {
        window.location.href = e.target.href;
      });
    },
  },
};
</script>

<style module>
.centeredRow {
  @apply tw-text-center tw-flex tw-justify-center;
}

.siteLogoImage {
  height: 4.375rem;
}

.footerLinksRow {
  max-width: 45.625rem;
}

.footerLinkItem {
  font-size: 0.8125rem;
  line-height: 2rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
</style>
