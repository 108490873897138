import { useToast } from 'vue-toast-notification';

export default defineNuxtPlugin(() => {
  const toast = useToast({
    dismissible: true,
    position: 'top-right',
  });

  return {
    provide: {
      toast,
    },
  };
});
