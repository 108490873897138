import routerOptions0 from "/buddy/pipeline-fs/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/buddy/pipeline-fs/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}