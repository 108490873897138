import { navigateTo } from 'nuxt/app';

/**
 * Redirect legacy explore routes to new explore routes.
 * - Redirect from /explore/:type/:id/10-day-forecast to /explore/:type/:id/snow-forecast
 * - Redirect from /explore/:type/:id/10-day-history to /explore/:type/:id/snow-history
 * - Redirect from /explore/:type/:id/summary to /explore/:type/:id/snow-summary
 * @see
 * - https://deltener.com/blog/creating-redirects-with-nuxt/
 * - https://masteringnuxt.com/blog/how-to-redirect-in-nuxt-every-single-way
 */
export default defineNuxtRouteMiddleware((to) => {
  const match10DayForecast = to?.fullPath.match(/\/explore\/(.+)\/(.+)\/10-day-forecast/);
  const match10DayHistory = to?.fullPath.match(/\/explore\/(.+)\/(.+)\/10-day-history/);
  const matchSummary = to?.fullPath.match(/\/explore\/(.+)\/(.+)\/summary/);

  let path;

  if (match10DayForecast) {
    path = `/explore/${match10DayForecast[1]}/${match10DayForecast[2]}/snow-forecast`;
  }
  else if (match10DayHistory) {
    path = `/explore/${match10DayHistory[1]}/${match10DayHistory[2]}/snow-history`;
  }
  else if (matchSummary) {
    path = `/explore/${matchSummary[1]}/${matchSummary[2]}/snow-summary`;
  }

  if (path) {
    const options = { redirectCode: 308 };
    return navigateTo({ path, query: to.query }, options);
  }
});
