<template>
  <small
    :class="containerClass"
  >
    <font-awesome-icon
      v-if="icon"
      class="tw-mr-1"
      :icon="icon"
    />
    {{ text }}
  </small>
</template>

<script>
export default {
  name: 'Badge',

  props: {
    // Replace default classes with custom classes. This is useful if parent component needs to use
    // classes that conflict with the default classes.
    customClasses: {
      type: [String, Array],
      default: null,
    },
    extraSmall: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },

  computed: {
    containerClass() {
      const defaultClasses = [
        'tw-inline-block',
        'tw-border',
        'tw-rounded',
        'tw-font-bold',
        'tw-px-1.5',
        this.extraSmall ? this.$style.extraSmall : 'tw-py-0.5 tw-text-xs',
        this.$style.container,
      ];

      return this.customClasses || defaultClasses;
    },
  },
};
</script>

<style module>
.container {
  height: fit-content;
}

.extraSmall {
  font-size: 0.75rem;
  line-height: 0.875rem;
}
</style>
