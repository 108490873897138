/* eslint camelcase: off */
import { defineStore } from 'pinia';
import { sessionStorageService } from '@@/utils/StorageService';

export const loginViews = {
  buyNowEnterEmail: 'buyNowEnterEmail',
  buyNowLinkSent: 'buyNowLinkSent',
  buyNowEnterPassword: 'buyNowEnterPassword',
  changeEmailEnterEmail: 'changeEmailEnterEmail',
  changeEmailLinkSent: 'changeEmailLinkSent',
  deleteAccount: 'deleteAccount',
  deleteAccountLinkSent: 'deleteAccountLinkSent',
  forgotPasswordEnterEmail: 'forgotPasswordEnterEmail',
  forgotPasswordLinkSent: 'forgotPasswordLinkSent',
  forgotPasswordEnterPassword: 'forgotPasswordEnterPassword',
  loginEnterEmail: 'loginEnterEmail',
  loginEnterPassword: 'loginEnterPassword',
  loginLinkSent: 'loginLinkSent',
  registerEnterEmail: 'registerEnterEmail',
  registerLinkSent: 'registerLinkSent',
  registerLinkVerified: 'registerLinkVerified',
};

export const useLoginStore = defineStore('login', {
  state: () => ({
    credentials: {
      email: null,
      passcode_validate: null,
      password: null,
    },
    loginError: null,
    loginView: loginViews.loginEnterEmail,
    promoCode: null,
  }),

  actions: {
    async getPromoCode(promoCode) {
      if (promoCode) {
        try {
          const { $api } = useNuxtApp();
          const url = `/meta/promo-codes/${promoCode}`;
          const { promo_code } = await $api.get(url);
          this.setPromoCode(promo_code);
        }
        catch (exp) {
          // Do nothing if unable to lookup the promo code
        }
      }
    },

    async getRecaptchaToken() {
      const { recaptchaPublicKey } = useRuntimeConfig().public;

      return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
          try {
            window
              .grecaptcha
              .execute(recaptchaPublicKey, { action: 'submit' })
              .then((recaptcha_token) => resolve(recaptcha_token));
          }
          catch (e) {
            reject(e || new Error('Unknown error getting recaptcha token'));
          }
        });
      });
    },

    async makeLoginLinkRequest(payload = {}) {
      const { $api } = useNuxtApp();
      const { request_passcode_only = false, return_to } = payload;
      const { email } = this.credentials;
      const url = '/user/login/link/request';

      const body = {
        email,
        request_passcode: true,
        request_passcode_only,
        return_to,
      };

      const response = await $api.post(url, undefined, body);

      if (response.passcode_sent) {
        const { passcode_validate } = response;
        this.setCredentials({ passcode_validate });
      }

      return response.link_sent;
    },

    async makeLoginRequest(payload = {}) {
      const { $api } = useNuxtApp();
      const { return_to } = payload;
      const { email, password } = this.credentials;
      const url = '/user/login';

      const body = {
        email,
        password,
        request_passcode: true,
        return_to,
      };

      if (this.promoCode?.is_usable) {
        body.promo_code = this.promoCode.code;
      }

      const response = await $api.post(url, undefined, body);

      const {
        link_sent,
        messages,
        passcode_sent,
        passcode_validate,
        promo_code,
        user,
        user_has_password,
      } = response;

      if (link_sent) {
        this.setLoginView(loginViews.loginLinkSent);

        if (passcode_sent) {
          this.setCredentials({ passcode_validate });
        }
      }
      else if (user_has_password) {
        this.setLoginView(loginViews.loginEnterPassword);
      }

      return { messages, promo_code, user };
    },

    async makeLoginPasscodeRequest(payload = {}) {
      const { $api } = useNuxtApp();
      const { passcode } = payload;
      const { email, passcode_validate } = this.credentials;
      const url = '/user/login/passcode';

      const body = {
        email,
        passcode,
        passcode_validate,
      };

      if (this.promoCode?.is_usable) {
        body.promo_code = this.promoCode.code;
      }

      const response = await $api.post(url, undefined, body);

      return response;
    },

    async makeLogoutRequest() {
      const { $api } = useNuxtApp();

      sessionStorageService.clear();

      const url = '/user/logout';
      return $api.post(url);
    },

    /**
     * @todo Rename to makeSettingsPasswordLinkRequest to be consistent with URL
     */
    async makePasswordLinkRequest(payload = {}) {
      const { $api } = useNuxtApp();

      const recaptcha_token = await this.getRecaptchaToken();

      const { return_to } = payload;
      const { email } = this.credentials;
      const url = '/user/settings/password/link/request';
      const body = { email, recaptcha_token, return_to };

      const response = await $api.post(url, undefined, body);

      const { link_sent } = response;

      if (link_sent) {
        this.setLoginView(loginViews.forgotPasswordLinkSent);
      }

      return link_sent;
    },

    async makeSettingsDeleteLinkRequest() {
      const { $api } = useNuxtApp();
      const url = '/user/settings/delete/link/request';

      const response = await $api.post(url);

      const { link_sent } = response;

      if (link_sent) {
        this.setLoginView(loginViews.deleteAccountLinkSent);
      }

      return link_sent;
    },

    async makeSettingsEmailLinkRequest() {
      const { $api } = useNuxtApp();
      const { email } = this.credentials;
      const url = '/user/settings/email/link/request';
      const body = { email };

      const response = await $api.post(url, undefined, body);

      const { link_sent } = response;

      if (link_sent) {
        this.setLoginView(loginViews.changeEmailLinkSent);
      }

      return link_sent;
    },

    async makeSettingsPasswordLinkVerifyRequest(payload) {
      const { $api } = useNuxtApp();
      const { password } = this.credentials;
      const url = '/user/settings/password/link/verify';
      const body = { password, payload };

      const response = await $api.post(url, undefined, body);

      return response.user;
    },

    async makeRegisterLinkRequest(payload = {}) {
      const { $api } = useNuxtApp();

      const recaptcha_token = await this.getRecaptchaToken();

      const {
        request_passcode_only = false,
        return_to = '/user/favorites',
        trial_requested = true,
      } = payload;
      const { email } = this.credentials;

      const url = '/user/register/link/request';
      const body = {
        email,
        recaptcha_token,
        return_to,
        request_passcode: true,
        request_passcode_only,
        trial_requested,
      };

      if (this.promoCode?.is_usable) {
        body.promo_code = this.promoCode.code;
      }

      const response = await $api.post(url, undefined, body);

      const { link_sent, passcode_sent } = response;

      if (link_sent) {
        if (passcode_sent) {
          const { passcode_validate } = response;
          this.setCredentials({ passcode_validate });
        }

        if (this.loginView === loginViews.buyNowEnterEmail) {
          this.setLoginView(loginViews.buyNowLinkSent);
        }
        else if (this.loginView === loginViews.registerEnterEmail) {
          this.setLoginView(loginViews.registerLinkSent);
        }
      }

      return link_sent;
    },

    async makeRegisterPasscodeRequest(payload = {}) {
      const { $api } = useNuxtApp();
      const { passcode, trial_requested = true } = payload;
      const { email, passcode_validate } = this.credentials;
      const url = '/user/register/passcode';

      const body = {
        email,
        passcode,
        passcode_validate,
        trial_requested,
      };

      if (this.promoCode?.is_usable) {
        body.promo_code = this.promoCode.code;
      }

      const response = await $api.post(url, undefined, body);
      checkForOpenMountainApiError(response);

      return response;
    },

    async makeBuyNowLinkRequest(params = {}) {
      const {
        userExists = false,
        hasPassword = false,
        linkRequested = false,
      } = params;

      const output = {
        linkSent: false,
        linkRequested,
      };

      if (userExists && hasPassword && !linkRequested) {
        this.setLoginView(loginViews.buyNowEnterPassword);
        return output;
      }

      // send a passcode email
      output.linkRequested = true;
      output.linkSent = await (
        (userExists)
          ? this.makeLoginLinkRequest({
              request_passcode_only: true,
            })
          : this.makeRegisterLinkRequest({
              trial_requested: false,
              request_passcode_only: true,
            })
      );

      if (output.linkSent) {
        this.setLoginView(loginViews.buyNowLinkSent);
      }

      return output;
    },

    makeRegisterCheckRequest() {
      const { $api } = useNuxtApp();
      const { email } = this.credentials;
      const url = '/user/register/check';
      return $api.get(url, { email });
    },

    setLoginView(loginView) {
      if (Object.keys(loginViews).includes(loginView)) {
        this.loginView = loginView;
      }
    },

    setCredentials(payload = {}) {
      const { email, passcode_validate, password } = payload;

      if (email !== undefined) {
        this.credentials.email = email;
      }

      if (passcode_validate !== undefined) {
        this.credentials.passcode_validate = passcode_validate;
      }

      if (password !== undefined) {
        this.credentials.password = password;
      }
    },

    setLoginError(loginError) {
      this.loginError = loginError;
    },

    setPromoCode(promoCode) {
      this.promoCode = promoCode;
    },
  },
});
