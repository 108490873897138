<template>
  <Badge
    :custom-classes="customClasses"
    :icon="icon"
    :text="text"
  />
</template>

<script>
export default {
  name: 'AllAccessBadge',

  props: {
    classNames: {
      type: Array,
      default() {
        return [];
      },
    },
    icon: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'All-Access',
    },
  },

  computed: {
    customClasses() {
      return [
        'tw-inline-block',
        'tw-font-medium',
        this.large ? 'tw-leading-5 tw-py-px' : 'tw-leading-3 tw-py-1',
        'tw-px-2',
        'tw-uppercase',
        'tw-text-white',
        this.$style.container,
        ...this.classNames,
      ];
    },
  },
};
</script>

<style module>
.container {
  background-color: var(--saturated-teal);
  border-radius: 0.625rem;
  font-size: 0.625rem;
}
</style>
