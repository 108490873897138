<script setup>
// Redirect to last compare page viewed or the summary page if none have been viewed recently.

import { useMetaStore } from '@@/stores/Meta';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';

definePageMeta({ layout: 'authorized' });

const { query } = useRoute();
const metaStore = useMetaStore();
const { getDefaultLocationCompareView } = metaStore;

const userFavoritesStore = useUserFavoritesStore();
const activeList = userFavoritesStore.getActiveList;

const { lastComparePageCookie } = useCompareUtils(activeList);
let comparePage;

if (lastComparePageCookie.value && userFavoritesStore.getLocationIdsForActiveList?.length > 0) {
  comparePage = lastComparePageCookie.value;
}

// if the user doesn't have a compare page cookie set, use a default
if (!comparePage) {
  // use default value from API to send user to our preferred page
  comparePage = getDefaultLocationCompareView;
  if (!comparePage) {
    // otherwise, default to snow summary for winter list or weather for summer list
    // we should really never get here
    const activeFavoriteListType = metaStore.favorite_list_types
      .find(({ id }) => id === activeList.list_type_id);
    comparePage = activeFavoriteListType.name === 'Winter' ? 'snow-summary' : 'weather';
  }
}

const path = `/user/favorites/locations/${comparePage}`;
navigateTo({ path, query }, { replace: true });
</script>

<template>
  <div />
</template>
