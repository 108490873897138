/**
 * Don't import @fancyapps/ui when viewing component stories in Histoire because when it is
 * imported it references the window object, which is undefined in Histoire, and these runtinme
 * errors sometimes prevent Histoire from running.
 * @see https://github.com/histoire-dev/histoire/issues/487
 */
export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  if (config.public.isHistoire) {
    console.log('Fancyapps: Not loaded in Histoire!');
    return;
  }

  const { Fancybox } = await import('@fancyapps/ui');
  window.Fancybox = Fancybox;
});
