<script setup>
defineProps({
  error: Object,
});
</script>

<template>
  <Main>
    <template #default>
      <div class="container page-content">
        <ErrorPage :error="error" />
      </div>
    </template>
  </Main>
</template>
