<template>
  <p :class="classNames">
    <CardCloseButton
      v-if="is_dismissable"
      :button-class="buttonClass"
      @click="$emit('dismiss')"
    />
    <font-awesome-icon
      v-if="icon"
      :class="iconClassNames"
      :icon="icon"
    />
    {{ message }}
    <Button
      v-if="action && action_url"
      class="tw-block md:tw-inline-block tw-mt-2 md:tw-mt-0 md:tw-ml-2"
      :display-block="buttonDisplayBlock"
      :href="action_url"
      :target="target"
      :type="actionButtonType"
    >
      {{ action }}
    </Button>
  </p>
</template>

<script>
/* eslint vue/prop-name-casing: off */

import { ButtonTypes } from '@@/components/Common/Button.vue';
import { isScreenSm } from '@@/utils/CommonUtils';

export const bannerTypes = {
  disclaimer: 'd',
  error: 'e',
  info: 'i',
  membership: 'm',
  warning: 'w',
};

export default {
  name: 'Banner',

  props: {
    action: {
      type: String,
      default: '',
    },
    action_url: {
      type: String,
      default: '',
    },
    icon: {
      type: [String, Array],
      default: null,
    },
    iconClass: {
      type: [String, Array],
      default: null,
    },
    is_dismissable: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'm',
      validator(value) {
        // SEE: https://github.com/cloudninewx/OpenMountain-API/blob/develop/models/User.js#L200-L207
        return Object.values(bannerTypes).includes(value);
      },
    },
  },

  emits: ['dismiss'],

  computed: {
    actionButtonType() {
      if (this.type === bannerTypes.disclaimer) {
        return ButtonTypes.help;
      }

      return ButtonTypes.bannerBuyNow;
    },

    buttonClass() {
      if (this.type === bannerTypes.disclaimer) {
        return 'text-regular-color';
      }

      if (this.type === bannerTypes.warning) {
        return 'tw-text-black';
      }

      return 'tw-text-white';
    },

    buttonDisplayBlock() {
      return isScreenSm();
    },

    classNames() {
      const typeClassNames = {
        [bannerTypes.disclaimer]: 'tw-border-2 disclaimer',
        [bannerTypes.info]: this.$style.info,
        [bannerTypes.error]: this.$style.error,
        [bannerTypes.membership]: this.$style.membership,
        [bannerTypes.warning]: this.$style.warning,
      };

      return [
        'tw-relative',
        'tw-p-4 tw-rounded-lg',
        'tw-text-base tw-font-medium tw-text-center',
        typeClassNames[this.type],
      ];
    },

    iconClassNames() {
      const iconClassNames = ['tw-mr-1'];

      if (Array.isArray(this.iconClass)) {
        iconClassNames.push(...this.iconClass);
      }
      else if (typeof this.iconClass === 'string') {
        iconClassNames.push(this.iconClass);
      }

      return iconClassNames;
    },

    target() {
      if (import.meta.client && this.action_url) {
        const url = new URL(this.action_url);

        if (url.origin !== window.location.origin) {
          return '_blank';
        }
      }

      return null;
    },
  },
};
</script>

<style module>
/**
 * @todo At somepoint these background-color declarations should be moved to global CSS classes.
 * e.g. background-color-satured-red, background-color-light-blue, etc.
 */
.error {
  background-color: var(--light-red);
  border: 0.125rem solid var(--saturated-red);
  color: white;
}

.info {
  background-color: var(--light-blue);
  border: 0.125rem solid var(--saturated-blue-50);
  color: white;
}

.membership {
  background-color: var(--saturated-teal);
  border: 0.125rem solid var(--dark-teal);
  color: white;
}

.warning {
  background-color: var(--light-orange);
  border: 0.125rem solid var(--saturated-orange);
  color: black;
}
</style>
