<template>
  <div :class="$style.container">
    <svg
      :class="$style.spinner"
      viewBox="0 0 50 50"
    >
      <circle
        :class="$style.path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="3"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
};
</script>

<style module>
/*
 * Tailwind.css is not used in this component because it may be rendered outside the top level
 * #root element which is used to namespace Tailwind selectors.
 */
.container {
  align-items: center;
  background-color: inherit;
  color: var(--loading-indicator-color);
  display: flex;
  font-size: 30px;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.spinner {
  animation: rotate 2s linear infinite;
  height: 50px;
  width: 50px;
}

.path {
  animation: dash 1.5s ease-in-out infinite;
  stroke: var(--loading-indicator-color);
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
