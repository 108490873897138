import { defineStore } from 'pinia';

export const useSearchStore = defineStore('search', {
  state: () => ({}),

  actions: {
    async searchAll(payload = {}) {
      const { $api } = useNuxtApp();
      const { limit = 20, q = '' } = payload;
      const search_types = ['country', 'daily-read', 'location', 'region', 'season-pass', 'state'].join(',');

      const { results } = await $api.get('/search', { limit, q, search_types });

      return results;
    },

    async searchLocations(payload = {}) {
      const { $api } = useNuxtApp();
      const { limit = 20, q = '', skip = 0 } = payload;

      const { locations } = await $api.get('/search/locations', { limit, q, skip });

      return locations;
    },
  },
});
