const createStorageService = (storageName) => ({
  getItem(key) {
    try {
      const value = window?.[storageName]?.getItem(key);
      return JSON.parse(value);
    }
    catch (e) {
      return undefined;
    }
  },

  removeItem(key) {
    try {
      window?.[storageName]?.removeItem(key);
    }
    catch (e) {
      // Do nothing
    }
  },

  setItem(key, value) {
    try {
      window?.[storageName]?.setItem(key, JSON.stringify(value));
    }
    catch (e) {
      // Do nothing
    }
  },

  clear() {
    try {
      window?.[storageName]?.clear();
    }
    catch (e) {
      // Do nothing
    }
  },
});

export const localStorageService = createStorageService('localStorage');
export const sessionStorageService = createStorageService('sessionStorage');
