<template>
  <div :class="$style.container">
    <input
      :class="computedClass"
      max="1"
      min="0"
      step="1"
      type="range"
      :value="computedValue"
      @input="handleInput"
    >
  </div>
</template>

<script>
/**
 * Range Input Style adopted from CSS tricks
 * @see https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
 */
export default {
  name: 'ToggleSwitch',

  props: {
    useBrandColor: {
      type: Boolean,
      value: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    computedClass() {
      return [
        this.modelValue === true ? this.$style.on : this.$style.off,
        this.useBrandColor ? this.$style.snowColor : undefined,
      ];
    },

    computedValue() {
      return this.modelValue === true ? '1' : '0';
    },
  },

  methods: {
    handleInput($event) {
      this.$emit('update:modelValue', $event.target.value === '1');
    },
  },
};
</script>

<style module>
/*
 * Note that selectors must be repeated, they cannot be grouped together. This is because the
 * browser will stop parsing the selectors when it doesn't understand one, and since these
 * selectors are all browser specific that would lead to not applying the styles correctly.
 */

.container {
  --thumb-border-radius: 50%;
  --thumb-height: 1rem;
  --thumb-width: 1rem;
  --track-border-radius: 0.75rem;
  --track-color-off: var(--lighter-gray);
  --track-color-on: black;
  --track-height: 1.5rem;
  --track-width: 3.125rem;
}

/* Reset default styles */
.container input[type="range"] {
  appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
  display: block;
  width: var(--track-width); /* Specific width is required for Firefox. */
}

.container input[type="range"]:focus {
  outline: none; /* Removes the blue border. */
}

/* Style the track */

.container input[type="range"]::-webkit-slider-runnable-track {
  border: 0 none;
  border-radius: var(--track-border-radius);
  cursor: pointer;
  height: var(--track-height);
  padding: 4px 3px 2px;
  transition: background 250ms ease-in-out;
  width: var(--track-width);
}

.container input.off[type="range"]::-webkit-slider-runnable-track {
  background: var(--track-color-off);
}

.container input.on[type="range"]::-webkit-slider-runnable-track {
  background: var(--track-color-on);
}

.container input.on.snowColor[type="range"]::-webkit-slider-runnable-track {
  background: var(--saturated-blue);
}

[data-theme="dark"] .container .on.snowColor[type="range"]::-webkit-slider-runnable-track,
[data-color-theme="dark"] .container .on.snowColor[type="range"]::-webkit-slider-runnable-track {
  background: var(--light-blue);
}

.container input[type="range"]::-moz-range-track {
  border: 0 none;
  border-radius: var(--track-border-radius);
  cursor: pointer;
  height: var(--track-height);
  padding: 0 3px;
  transition: background 250ms ease-in-out;
  width: var(--track-width);
}

.container input[type="range"][value="0"]::-moz-range-track {
  background: var(--track-color-off);
}

.container input[type="range"][value="1"]::-moz-range-track {
  background: var(--track-color-on);
}

.container input.snowColor[type="range"][value="1"]::-moz-range-track {
  background: var(--saturated-blue);
}

[data-theme="dark"] .container .snowColor[type="range"][value="1"]::-moz-range-track,
[data-color-theme="dark"] .container .snowColor[type="range"][value="1"]::-moz-range-track {
  background: var(--light-blue);
}

/* Style the thumb */

.container input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background: white;
  border: 0 none;
  border-radius: var(--thumb-border-radius);
  cursor: pointer;
  height: var(--thumb-height);
  width: var(--thumb-width);
}

.container input[type="range"]::-moz-range-thumb {
  background: white;
  border: 0 none;
  border-radius: var(--thumb-border-radius);
  cursor: pointer;
  height: var(--thumb-height);
  width: var(--thumb-width);
}
</style>
