/* eslint camelcase: off */
import { defineStore } from 'pinia';
import { localStorageService } from '@@/utils/StorageService';
import { roundToPrecision } from '@@/utils/CommonUtils';
import { useLocationStore } from '@@/stores/Location';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';
import { useNuxtApp } from 'nuxt/app';

export const colors = {
  textColorLight: '#ffffff',
  textHaloColorLight: '#000000',
  textColorDark: '#ffffff',
  textHaloColorDark: '#000000',
};

export const mapCardTypes = {
  avalancheForecast: 'avalancheForecast',
  locationForecast: 'locationForecast',
  removeCustomLocationDialog: 'removeCustomLocationDialog',
  saveCustomLocation: 'saveCustomLocation',
  selectFavoriteList: 'selectFavoriteList',
};

const setComputedSortWeight = (categories, sources) => {
  return sources.reduce((acc, source) => {
    const category = categories.find((c) => c.id === source.category_id);

    acc[source.short_name] = {
      ...source,
      computed_sort_weight: (category.sort_weight * 100) + source.sort_weight,
    };

    return acc;
  }, {});
};

export const getState = () => ({
  data: {
    baseMaps: null,
    categories: null,
    sources: null,
  },
  style: {
    maskColor: '#000000',
    maskOpacity: 0.2,
    textColor: colors.textColorLight,
    textFont: ['Open Sans Bold', 'Open Sans Regular', 'Arial Unicode MS Regular'],
    textHaloBlur: 0,
    textHaloColor: colors.textHaloColorLight,
    textHaloWidth: 1,
    textLetterSpacing: 0.0625,
    textLineHeight: 1.2,
    textPadding: 20,
    textSize: 13,
  },
  ui: {
    bearing: 0,
    canSaveMapState: true,
    controlsToShow: [
      'Compass',
      'Toggle3D',
      'Geolocate',
      'Navigation',
      'Opacity',
    ],
    center: null,
    compareId: null,
    compareType: null,
    currentBaseMap: null,
    currentFrame: 0,
    currentOverlay: null,
    currentTimestamp: null,
    isFavorites: null,
    isForecast: null,
    isFullScreen: true,
    isLayerDrawerOpen: false,
    isMapIdle: null,
    mapCard: null,
    mapLocation: null,
    maskMessage: null,
    numberOfFrames: 0,
    opacity: null,
    overlayCoverageBbox: null,
    overlayFieldControlOptions: null,
    overlayFieldControlValue: null,
    pitch: 0,
    selectedLayerDrawerTab: 0,
    shouldReset3D: false,
    showAnimationControl: false,
    showLocations: false,
    showMaskMessage: false,
    showOpacity: false,
    visibleFavoriteLists: [],
    visibleLocationTypes: [],
    zoom: null,
  },
});

export const useMapStore = defineStore('map', {
  state: () => getState(),

  actions: {
    async fetchMapSourcesMeta({ noCommit = false, sources }) {
      const { $api } = useNuxtApp();
      const short_names = sources.map(({ short_name }) => short_name).join(',');
      const url = '/maps/sources/meta';

      const response = await $api.get(url, { short_names });

      const baseMaps = response.styles.reduce((acc, baseMap) => {
        acc[baseMap.short_name] = baseMap;
        return acc;
      }, {});

      const response2 = {
        baseMaps,
        categories: response.categories,
        sources: setComputedSortWeight(response.categories, response.sources),
      };

      if (noCommit === false) {
        const { baseMaps, categories, sources } = response2;
        this.data.baseMaps = baseMaps;
        this.data.categories = categories;
        this.data.sources = sources;
      }

      return response2;
    },

    /**
     * Fetch a fresh copy of the specified map source, and if there is any difference between the
     * timestamps of the last tile in the fresh tiles and the current tiles, then update the map
     * source in the state and inform the caller if the source was updated.
     */
    async fetchMapSourcesTiles({ source }) {
      const { short_name } = source;
      const [tileType] = source.tile_types;

      const { $api } = useNuxtApp();
      const url = '/maps/sources/tiles';
      const { sources } = await $api.get(url, { sources: JSON.stringify([source]) });

      const currentTiles = this.data.sources?.[short_name]?.tiles?.[tileType];
      const currentLastTimestamp = currentTiles?.[currentTiles.length - 1]?.source_timestamp;
      const newTiles = sources[0].tiles[tileType];
      const newLastTimestamp = newTiles[newTiles.length - 1].source_timestamp;

      const wasUpdated = !!(currentLastTimestamp && currentLastTimestamp !== newLastTimestamp);

      if (wasUpdated || !currentLastTimestamp) {
        this.data.sources[short_name] = setComputedSortWeight(this.data.categories, sources)[short_name];
      }

      return wasUpdated;
    },

    /**
     * Remove invalid map state values obtained from either the URL or local storage so that the user
     * doesn't wind up being unable to load the Map page if they enter invalid data in the URL and it
     * gets saved to local storage. Invalid values will be removed from the mapState so that the
     * caller can ignore them and use default values instead.
     *
     * Note that this method doesn't really belong in Vuex, it has nothing to do with the current
     * Map state, but it was placed here since it's easier write unit tests for it here!
     */
    filterMapState(payload = {}) {
      const metaStore = useMetaStore();
      const { isAllAccess = false, mapState = {} } = payload;

      const {
        bearing,
        center,
        currentBaseMap,
        currentOverlay,
        isFullScreen,
        overlayFieldControlValue,
        pitch,
        shortname,
        showLocations,
        visibleFavoriteLists,
        visibleLocationTypes,
        zoom,
      } = mapState;

      const filteredMapState = {
        bearing,
        center,
        currentBaseMap,
        currentOverlay,
        isFullScreen,
        overlayFieldControlValue,
        pitch,
        shortname,
        showLocations,
        visibleFavoriteLists,
        visibleLocationTypes,
        zoom,
      };

      const { baseMaps, sources } = this.data;

      if (typeof bearing !== 'number' || Number.isNaN(bearing)) {
        delete filteredMapState.bearing;
      }

      if (!center
        || typeof center !== 'object'
        || typeof center.lat !== 'number'
        || typeof center.lng !== 'number'
        || center.lat < -90
        || center.lat > 90
        || center.lng < -180
        || center.lng > 180) {
        delete filteredMapState.center;
      }

      if (!currentBaseMap
        || !baseMaps?.[currentBaseMap]
        || (!isAllAccess && baseMaps?.[currentBaseMap].is_all_access)) {
        delete filteredMapState.currentBaseMap;
      }

      if (!currentOverlay
        || !sources[currentOverlay]
        || (!isAllAccess && sources[currentOverlay].is_all_access)) {
        delete filteredMapState.currentOverlay;
      }

      if (typeof isFullScreen !== 'boolean') {
        delete filteredMapState.isFullScreen;
      }

      if (typeof overlayFieldControlValue !== 'string') {
        delete filteredMapState.overlayFieldControlValue;
      }

      if (typeof pitch !== 'number' || Number.isNaN(pitch)) {
        delete filteredMapState.pitch;
      }

      if (typeof shortname !== 'string') {
        delete filteredMapState.shortname;
      }

      if (typeof showLocations !== 'boolean') {
        delete filteredMapState.showLocations;
      }

      if ((Array.isArray(visibleFavoriteLists)
        && !visibleFavoriteLists.every((id) => typeof id === 'number'))
      || visibleFavoriteLists !== null) {
        delete filteredMapState.visibleFavoriteLists;
      }

      const visibleLocationTypeSlugs = metaStore.location_types_maps
        .map(({ slug }) => slug);

      if (!Array.isArray(visibleLocationTypes)
        || !visibleLocationTypes.every((slug) => visibleLocationTypeSlugs.includes(slug))) {
        delete filteredMapState.visibleLocationTypes;
      }

      if (typeof zoom !== 'number' || zoom < 0 || zoom > 20) {
        delete filteredMapState.zoom;
      }

      return filteredMapState;
    },

    getMapStateFromUrl({ $route }) {
      let mapStateFromUrl = {};

      try {
        let center = null;
        let currentBaseMap = null;
        let currentOverlay = null;
        let lat;
        let lng;
        let zoom = null;

        const { basemap, latlngzoom, overlay } = $route?.params || {};

        if (basemap || latlngzoom || overlay) {
          if (basemap) {
            currentBaseMap = basemap;
          }

          if (overlay) {
            currentOverlay = Object.values(this.data.sources ?? {})
              .find((source) => source.slug.toLowerCase() === overlay.toLowerCase());
            currentOverlay = currentOverlay ? currentOverlay.short_name : null;
          }

          if (latlngzoom) {
            const parts = latlngzoom.replace(/^@/, '').replace(/z$/, '').split(',');

            if (parts?.length === 3) {
              ([lat, lng, zoom] = parts);
              center = { lng: Number(lng), lat: Number(lat) };
              zoom = Number(zoom);
            }
          }

          mapStateFromUrl = {
            center,
            currentBaseMap,
            currentOverlay,
            zoom,
          };
        }
      }
      catch (exp) {
        // Do nothing, ignore errors parsing values from URL
      }

      if ($route?.hash) {
        mapStateFromUrl.shortname = $route.hash.replace(/^#/, '');
      }

      if ($route?.query?.weather_stations_field) {
        mapStateFromUrl.overlayFieldControlValue = $route.query.weather_stations_field;
      }

      if (typeof $route?.query?.bearing !== 'undefined') {
        const bearing = parseInt($route?.query?.bearing, 10);

        if (!Number.isNaN(bearing)) {
          mapStateFromUrl.bearing = bearing;
        }
      }

      if (typeof $route?.query?.pitch !== 'undefined') {
        const pitch = parseInt($route?.query?.pitch, 10);

        if (!Number.isNaN(pitch)) {
          mapStateFromUrl.pitch = pitch;
        }
      }

      return mapStateFromUrl;
    },

    getSavedMapState() {
      try {
        let savedMapState = localStorageService.getItem(this.savedMapStateKeyName);

        if (!savedMapState) {
          return {};
        }

        const {
          bearing,
          center,
          compareId,
          compareType,
          overlay,
          overlayFieldControlValue,
          pitch,
          visibleFavoriteLists,
          zoom,
        } = savedMapState;
        const currentBaseMap = savedMapState.style;
        const isFullScreen = savedMapState.isFullScreen;
        const showLocations = savedMapState.pointsVisible;
        const visibleLocationTypes = savedMapState.visibleTypes;

        const currentOverlay = Object
          .keys(this.data.sources ?? {})
          .find((key) => key === overlay) || null;

        return {
          bearing,
          center,
          compareId,
          compareType,
          currentBaseMap,
          currentOverlay,
          isFullScreen,
          overlayFieldControlValue,
          pitch,
          showLocations,
          visibleFavoriteLists,
          visibleLocationTypes,
          zoom,
        };
      }
      catch (exp) {
        return {};
      }
    },

    resetState() {
      const originalState = getState();
      Object.keys(originalState).forEach((key) => this[key] = originalState[key]);
    },

    async saveMapState() {
      if (this.ui.canSaveMapState === false) {
        return;
      }

      const { savedMapStateKeyName } = this;

      const {
        bearing,
        compareId,
        compareType,
        currentBaseMap,
        currentOverlay,
        isFavorites,
        isFullScreen,
        overlayFieldControlValue,
        pitch,
        showLocations,
        visibleFavoriteLists,
        visibleLocationTypes,
      } = this.ui;

      // Copy the center from the ui state, so as not to modify the original, and then round the
      // lat, lng, and zoom to 4 digits of precision to keep the map state saved to local storage
      // in sync with the map state in the URL.
      const center = Object.assign({}, this.ui.center);
      center.lat = roundToPrecision(center.lat, 4);
      center.lng = roundToPrecision(center.lng, 4);
      const zoom = roundToPrecision(this.ui.zoom, 4);

      let mapState;

      if (compareId && compareType) {
        mapState = {
          center,
          compareId,
          compareType,
          zoom,
        };
      }
      else if (isFavorites) {
        mapState = {
          center,
          zoom,
        };
      }
      else {
        mapState = {
          bearing,
          center,
          isFullScreen,
          overlay: currentOverlay || 'none',
          overlayFieldControlValue,
          pitch,
          pointsVisible: showLocations,
          style: currentBaseMap,
          visibleFavoriteLists,
          visibleTypes: visibleLocationTypes,
          zoom,
        };
      }

      localStorageService.setItem(savedMapStateKeyName, mapState);
    },

    async setAvalancheCard(payload) {
      const { $api } = useNuxtApp();
      const { forecast_id, id, shortname } = payload;
      const path = `/avalanche/${id || shortname}${forecast_id ? `/forecasts/${forecast_id}` : ''}`;

      const { avalanche } = await $api.get(path);

      const mapCard = {
        data: { forecast: avalanche },
        type: mapCardTypes.avalancheForecast,
      };

      this.setMapCard(mapCard);
    },

    async setCurrentBaseMap(currentBaseMap) {
      this.ui.currentBaseMap = currentBaseMap;

      if (currentBaseMap === 'dark' || currentBaseMap === 'satellite') {
        this.style.textColor = colors.textColorDark;
        this.style.textHaloColor = colors.textHaloColorDark;
      }
      else {
        this.style.textColor = colors.textColorLight;
        this.style.textHaloColor = colors.textHaloColorLight;
      }

      await this.saveMapState();
    },

    setIsLayerDrawerOpen(isLayerDrawerOpen) {
      if (isLayerDrawerOpen) {
        this.ui.showOpacity = false;
      }

      this.ui.isLayerDrawerOpen = isLayerDrawerOpen;
    },

    setCanToggle3D(canToggle3D) {
      if (canToggle3D && !this.ui.controlsToShow.includes('Toggle3D')) {
        this.ui.controlsToShow.push('Toggle3D');
      }
      if (!canToggle3D && this.ui.controlsToShow.includes('Toggle3D')) {
        this.ui.controlsToShow = this.ui.controlsToShow.filter(
          (i) => i !== 'Toggle3D');
      }
    },

    setMapCard({ type, data }) {
      this.setMapUiProperty({ key: 'mapCard', value: { type, data } });
    },

    async setMapCustomLocation(payload) {
      const locationStore = useLocationStore();
      const userStore = useUserStore();

      const { coordinates } = payload;
      const { units } = userStore.preferences;
      const params = { coordinates, units, with_geocode: true };

      // Set initial map card with coordinates so map UI can be updated quicker
      const mapCard = {
        data: { coordinates },
        type: mapCardTypes.locationForecast,
      };
      this.setMapCard(mapCard);

      // Fetch location current forecast and then update map card
      const location = await locationStore.fetchForecastCurrent(params, { root: true });
      mapCard.data.location = location;
      this.setMapCard(mapCard);
    },

    /**
     * @todo
     * - Should the center just be set here instead? Is it necessary to save the lat, lng
     *   coordinations of the map card in a second place? Why not just set the center?
     * - Wrap this entire action in a try/catch block so we can gracefully fail if the specified
     *   shortname is for an invalid location.
     */
    async setMapLocation(payload) {
      const locationStore = useLocationStore();
      const userStore = useUserStore();

      const {
        lat,
        lng,
        shortname,
        updateCoords = true,
      } = payload;

      let location;

      // When passed a shortname fetch the location details for the specified location
      if (shortname) {
        const { units } = userStore.preferences;
        const params = { slug: shortname, units };

        location = await locationStore.fetchForecastCurrent(params, { root: true });
      }

      // When we have location details but no lat, lng coordinates then use the lat, lng from the
      // location details. Otherwise use the specified lat, lng coordinates when present.
      if (updateCoords && location && (!lat || !lng)) {
        let latitude;
        let longitude;

        if (location.coordinates) {
          ([longitude, latitude] = location.coordinates.point);
        }
        else {
          ({ latitude, longitude } = location);
        }

        this.setMapUiProperty({
          key: 'mapLocation',
          value: {
            lat: latitude,
            lng: longitude,
          },
        });
      }
      else if (updateCoords && lat && lng) {
        this.setMapUiProperty({ key: 'mapLocation', value: { lat, lng } });
      }

      // HACK: Don't set the map card if saving a custom location. Remove this in #233
      // SEE: https://github.com/cloudninewx/OpenMountain-Web/issues/233
      if (shortname && this.ui.mapCard?.data?.location?.slug === shortname) {
        return;
      }

      if (shortname) {
        const mapCard = {
          data: {
            location,
            shortname,
          },
          type: mapCardTypes.locationForecast,
        };

        this.setMapCard(mapCard);
      }
    },

    setMapUiProperties(properties = {}) {
      Object.entries(properties)
        .forEach(([key, value]) => this.setMapUiProperty({ key, value }));
      this.saveMapState();
    },

    setMapUiPropertiesNoSave(properties = {}) {
      Object.entries(properties)
        .forEach(([key, value]) => this.setMapUiProperty({ key, value }));
    },

    setShowAnimationControl(showAnimationControl) {
      this.ui.showAnimationControl = showAnimationControl;
    },

    setShowMaskMessage(showMaskMessage) {
      this.ui.showMaskMessage = showMaskMessage;
    },

    setMaskMessage(message) {
      this.ui.maskMessage = message;
    },

    setOverlayCoverageBbox(coverage) {
      this.ui.overlayCoverageBbox = coverage;
    },

    setMapUiProperty({ key, value }) {
      this.ui[key] = value;
    },

    setShowOpacity(showOpacity) {
      if (showOpacity) {
        this.ui.isLayerDrawerOpen = false;
      }

      this.ui.showOpacity = showOpacity;
    },
  },

  getters: {
    currentBaseMap() {
      const { currentBaseMap } = this.ui;
      const { baseMaps } = this.data;
      return baseMaps && baseMaps[currentBaseMap];
    },

    currentOverlay() {
      const { currentOverlay } = this.ui;
      const { sources } = this.data;
      return sources && sources[currentOverlay];
    },

    currentOverlayLegend() {
      const { overlayFieldControlValue } = this.ui;
      const { legends } = this?.currentOverlay || {};

      const legendIndex = legends
        ?.findIndex(({ fields }) => fields.includes(overlayFieldControlValue));

      if (legendIndex >= 0) {
        return legends[legendIndex];
      }

      return null;
    },

    is3D() {
      return this.ui.pitch !== 0;
    },

    controlsToShow() {
      return this.ui.controlsToShow;
    },

    savedMapStateKeyName() {
      if (this.ui.compareId && this.ui.compareType) {
        return 'mapState-compare';
      }

      if (this.ui.isFavorites) {
        return 'mapState-favorites';
      }

      return 'mapState-explore';
    },

    shouldShowOverlayFieldControl() {
      return !!(this.ui.overlayFieldControlOptions && this.ui.overlayFieldControlValue);
    },

    url() {
      const {
        bearing,
        center,
        compareId,
        compareType,
        currentBaseMap,
        mapCard,
        overlayFieldControlValue,
        pitch,
        zoom,
      } = this.ui;

      const { avalancheForecast, locationForecast } = mapCardTypes;

      const getLatLngFromCenter = () => {
        let lat;
        let lng;

        if (Array.isArray(center)) {
          ([lng, lat] = center);
        }
        else {
          ({ lng, lat } = center);
        }

        return { lat, lng };
      };

      const getLatLngZoomString = (lat, lng, myZoom) => `@${roundToPrecision(lat, 4)},${roundToPrecision(lng, 4)},${roundToPrecision(myZoom, 4)}z`;

      const { baseUrl } = useRuntimeConfig().public;
      let url;

      if (compareId && compareType && center && zoom) {
        const { lat, lng } = getLatLngFromCenter();
        const path = `/explore/${compareType}/${compareId}/map/${getLatLngZoomString(lat, lng, zoom)}`;
        url = new URL(path, baseUrl);
      }
      else if (currentBaseMap && center && zoom) {
        const { lat, lng } = getLatLngFromCenter();
        const path = `/map/${currentBaseMap}/${this.currentOverlay?.slug ?? 'none'}/${getLatLngZoomString(lat, lng, zoom)}`;
        url = new URL(path, baseUrl);

        if (overlayFieldControlValue && this.currentOverlay?.slug === 'weather-stations') {
          url.searchParams.set('weather_stations_field', overlayFieldControlValue);
        }

        if (typeof bearing === 'number' && bearing !== 0) {
          url.searchParams.set('bearing', parseInt(bearing, 10));
        }

        if (typeof pitch === 'number' && pitch !== 0) {
          url.searchParams.set('pitch', parseInt(pitch, 10));
        }

        if (mapCard?.type === avalancheForecast) {
          url.hash = `avy-${mapCard.data.forecast.slug}`;
        }
        else if (mapCard?.type === locationForecast && mapCard?.data?.shortname) {
          url.hash = `${mapCard.data.shortname}`;
        }
        else if (mapCard?.type === locationForecast && mapCard?.data?.location?.slug) {
          url.hash = `${mapCard.data.location.slug}`;
        }
      }

      if (url) {
        return url.toString().replace(baseUrl, '');
      }

      return '';
    },

    /**
     * Visible categories can be displayed in the UI.
     * @see https://github.com/cloudninewx/OpenMountain-API/blob/develop/docs/routes/maps_sources__POST.md#response-200
     */
    visibleCategories() {
      return this.data.categories
        ? Object.values(this.data.categories).filter((category) => category.visible)
        : [];
    },

    /**
     * Overlays with a visible category can be displayed in the UI. Note that they are returned to
     * the caller sorted by computed sort weight in ascending order.
     */
    visibleOverlays() {
      const visibleCategoryIds = this.visibleCategories.map((category) => category.id);

      if (!this.data.sources) {
        return [];
      }

      return Object.values(this.data.sources)
        .filter((source) => visibleCategoryIds.includes(source.category_id))
        .sort((a, b) => a.computed_sort_weight - b.computed_sort_weight);
    },
  },
});
