import { appendResponseHeader } from 'h3';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';
import { useUserStore } from '@@/stores/User';
import { useRequestHeaders } from 'nuxt/app';
import setCookieParser from 'set-cookie-parser';

export default defineNuxtPlugin((nuxtApp) => {
  const {
    environment,
    openMountainApiKey,
    openMountainApiUrl,
    userApiToken,
  } = useRuntimeConfig().public;

  const userFavoritesStore = useUserFavoritesStore();
  const userStore = useUserStore();

  const logRequest = (request, options) => {
    if (import.meta.client) {
      return;
    }

    const { $winstonLog } = nuxtApp;

    if (!$winstonLog) {
      return;
    }

    const { method } = options;
    const url = `${options.baseURL}${request}`;
    $winstonLog.info('api.onRequest', { method, url });

    const { cookie } = options?.headers || {};
    $winstonLog.debug('api.onRequest.cookie', { cookie });
  };

  const logResponseError = (request, options, response) => {
    if (import.meta.client) {
      return;
    }

    const { $winstonLog } = nuxtApp;

    if (!$winstonLog) {
      return;
    }

    const { method } = options;
    const url = request;
    const { _data, status, statusText } = response;

    // Don't log 401 errors for GET /user request, they just pollute logs with noise.
    if (status === 401 && url.includes('/user?')) {
      return;
    }

    const level = (status >= 500) ? 'error' : 'warn';

    // The error stack is completely useless and just adds noise
    $winstonLog[level]('api.onResponseError', {
      method,
      url,
      status,
      statusText,
      _data: JSON.stringify(_data).substring(0, 1000),
    });
  };

  const customFetch = $fetch.create({
    baseURL: openMountainApiUrl,

    async onRequest({ request, options }) {
      // Proxy set-cookie and X-OpenSnow-Token headers on API requests so they're passed in server
      // side API requests.
      // SEE: https://nuxt.com/docs/getting-started/data-fetching#pass-client-headers-to-the-api
      const headers = useRequestHeaders(['cookie', 'x-opensnow-token']);

      options.headers = {
        ...options.headers,
        ...headers,
      };

      // Only send this along if it's been defined in the .env file and running in local environment
      if (userApiToken && environment === 'local') {
        options.headers['x-opensnow-token'] = userApiToken;
      }

      // Pass cache_key on query string for all GET /user/* requests
      const cache_key = userStore.user?.cache_key;

      if (!cache_key) {
        logRequest(request, options);
        return;
      }

      if (String(request).includes('/user/') && String(options.method).toUpperCase() === 'GET') {
        options.query.cache_key = cache_key;
      }

      logRequest(request, options);
    },

    async onResponse({ response }) {
      const user = response?._data?.user;

      if (user) {
        const { cache_key: prevCacheKey } = userStore.user ?? {};

        if (prevCacheKey !== user.cache_key) {
          userStore.saveUser(user);
          userFavoritesStore.saveUser(user);
          // context is lost without this during middleware execution :-(
          await nuxtApp.runWithContext(async () => await userFavoritesStore.fetchUserFavoriteIds({ forceUpdate: true }));
        }
      }
    },

    onResponseError({ request, options, response }) {
      logResponseError(request, options, response);
    },
  });

  /**
   * Remove undefined or null values from query so they don't get passed on the URL
   */
  const filterQuery = (o) => Object.keys(o).reduce((acc, key) => {
    if (o[key] !== undefined && o[key] !== null) {
      acc[key] = o[key];
    }

    return acc;
  }, {});

  const getOpenMountainApiQuery = (query = {}) => {
    return filterQuery({
      v: 1,
      api_key: openMountainApiKey,
      ...query,
    });
  };

  const api = {
    delete(url, query = {}, body = {}, options = {}) {
      return customFetch(url, {
        method: 'DELETE',
        body,
        query: { ...getOpenMountainApiQuery(query) },
        ...options,
      });
    },

    get(url, query = {}, options = {}) {
      return customFetch(url, {
        method: 'GET',
        query: { ...getOpenMountainApiQuery(query) },
        ...options,
      });
    },

    /**
     * Used when making a server side request to GET /user so that cookies set in the API response
     * can be exposed to the client side. This allows pages like /weather-stations/:slug to be
     * rendered in the app unlocked for All-Access users when the X-OpenSnow-Token header is
     * specified by the app to authenticate the user.
     */
    async getWithCookie(event, url, query = {}, options = {}) {
      if (import.meta.client) {
        throw new Error('Do not call api.getWithCookie() from the client side!');
      }

      const response = await customFetch.raw(url, {
        method: 'GET',
        query: { ...getOpenMountainApiQuery(query) },
        ...options,
      });

      // Parse cookies from SSR API response header
      const cookies = setCookieParser.splitCookiesString(response.headers.get('set-cookie'));

      // Attach each cookie to the outgoing response
      cookies.forEach((cookie) => {
        appendResponseHeader(event, 'set-cookie', cookie);
      });

      // Return the data of the response
      return response._data;
    },

    post(url, query = {}, body = {}, options = {}) {
      return customFetch(url, {
        method: 'POST',
        body,
        query: { ...getOpenMountainApiQuery(query) },
        ...options,
      });
    },

    put(url, query = {}, body = {}, options = {}) {
      return customFetch(url, {
        method: 'PUT',
        body,
        query: { ...getOpenMountainApiQuery(query) },
        ...options,
      });
    },
  };

  return {
    provide: {
      api,
    },
  };
});
