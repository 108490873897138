<script setup>
import { ButtonTypes } from '@/components/Common/Button.vue';
import { useCookieWrapper } from '@/composables/useCookieWrapper';
import { useUserStore } from '@/stores/User';
import UserTrackingService from '@/utils/UserTrackingService';

const userStore = useUserStore();
const buttonClassNames = 'tw-px-2 tw-text-sm tw-leading-normal tw-whitespace-nowrap';

const cookieNoticeCookie = useCookieWrapper('cookienotice');
const cookieNoticeAcceptedCookie = useCookieWrapper('cookienotice-accepted');

const handleAcceptCookiesClick = async () => {
  if (userStore.isGuest) {
    cookieNoticeAcceptedCookie.value = true;
  }
  else {
    await userStore.updatePrivacy(true);
  }

  // Wait for next tick so cookie value set above is available when the UserTrackingService checks
  // for it!
  await nextTick();

  await UserTrackingService.startTracking();
  cookieNoticeCookie.value = true;
};

const handleRejectCookiesClick = async () => {
  if (userStore.isGuest) {
    cookieNoticeAcceptedCookie.value = false;
  }
  else {
    await userStore.updatePrivacy(false);
  }

  UserTrackingService.disableTracking();
  cookieNoticeCookie.value = true;
};
</script>

<template>
  <transition
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
  >
    <div
      v-if="!cookieNoticeCookie"
      id="cookieNotice"
      :class="[
        'tw-fixed tw-bottom-0 tw-left-0 tw-z-30',
        'tw-w-full tw-py-3 tw-px-5',
        'tw-flex tw-justify-between tw-items-center tw-flex-col md:tw-flex-row',
        'tw-border-t',
        'card-background-color border-color',
      ]"
    >
      <p class="tw-text-sm">
        We use cookies to understand how you use our service, to share relevant information, and to
        send anonymous data to a 3rd party advertising firm. Learn more in our
        <NuxtLink
          class="tw-font-bold link-color-brand"
          to="/privacy"
        >
          Privacy Policy.
        </NuxtLink>
      </p>
      <div class="tw-pt-4 md:tw-py-1 md:tw-ml-4 tw-whitespace-nowrap">
        <Button
          :additional-class-names="buttonClassNames"
          :type="ButtonTypes.primary"
          @click="handleAcceptCookiesClick"
        >
          Accept Cookies
        </Button>
        <Button
          :additional-class-names="buttonClassNames"
          :can-underline-on-hover="true"
          :type="ButtonTypes.none"
          @click="handleRejectCookiesClick"
        >
          Reject Cookies
        </Button>
      </div>
    </div>
  </transition>
</template>
