import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { timeAgoPlugin } from '@@/utils/TimeAgo';

export default defineNuxtPlugin((nuxtApp) => {
  const { $dayjs } = nuxtApp;
  $dayjs.extend(timeAgoPlugin);
});

/**
 * Manually setup Day.js for use in Histoire since Nuxt plug-ins apparently aren't registered by
 * default.
 * @see https://stackoverflow.com/questions/66559331/how-to-properly-use-dayjs-inside-vue-3-app-and-component
 */
export function setupDayjs(app) {
  dayjs.extend(advancedFormat);
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(timeAgoPlugin);
  app.config.globalProperties.$dayjs = dayjs;
};
