<template>
  <div :class="containerClass">
    <a
      :href="appStoreUrl"
      target="_blank"
    >
      <img
        alt="Download on the App Store"
        class="tw-inline-block tw-h-11 tw-mr-1"
        src="~/assets/img/AppStoreBlack.png"
      >
    </a>
    <a
      :href="playStoreUrl"
      target="_blank"
    >
      <img
        alt="Get it on Google Play"
        class="tw-inline-block tw-h-11 tw-ml-1"
        src="~/assets/img/PlayStoreBlack.png"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: 'DownloadAppButtons',

  props: {
    containerClass: {
      type: String,
      default: 'tw-my-6 tw-text-center',
    },
  },

  computed: {
    appStoreUrl() {
      return 'https://apps.apple.com/us/app/opensnow-ski-forecasts-reports/id780890907?ls=1';
    },

    playStoreUrl() {
      return 'https://play.google.com/store/apps/details?id=com.opensnow.android';
    },
  },
};
</script>
